<app-splash-container>
    <ng-container title><h2>Password Reset</h2></ng-container>
    <ng-container body>
        <p>Enter the email of your account. We will send you an email </p>
        <form [formGroup]="registerForm" (ngSubmit)="submit()">
            <div class="form-group">
                <label for="password">Password</label>&nbsp;<i class="fa fa-info-circle" placement="right" [ngbTooltip]="passwordTooltip" role="button" tabindex="0"></i>
                <ng-template #passwordTooltip>
                    Password must be between 6 and 32 characters in length
                </ng-template>
                <span class="sr-only" id="password-help"><ng-container [ngTemplateOutlet]="passwordTooltip"></ng-container></span>
                <input id="password" class="form-control" maxlength="32" minlength="6" formControlName="password" type="password" aria-describedby="password-help">
                <div id="inviteForm-validations" class="invalid-feedback" *ngIf="registerForm.dirty || registerForm.touched">
                    <div *ngIf="registerForm.get('password')?.errors?.required">
                        This field is required
                    </div>
                    <div *ngIf="registerForm.get('password')?.errors?.minlength || registerForm.get('password')?.errors?.maxLength">
                        Password must be between 6 and 32 characters in length
                    </div>
                </div>
            </div>
        
            <div class="float-right">
                <button class="btn btn-secondary alt" type="submit">Submit</button>
            </div>
        </form>
    </ng-container>
</app-splash-container>
