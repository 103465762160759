<div *ngIf="libraries.length === 0 && !isLoading && isAdmin" class="d-flex justify-content-center">
    <p>There are no libraries setup yet. Configure some in <a href="/admin/dashboard#libraries">Server settings</a>.</p>
</div>
<div *ngIf="libraries.length === 0 && !isLoading && !isAdmin" class="d-flex justify-content-center">
    <p>You haven't been granted access to any libraries.</p>
</div>

<app-carousel-reel [items]="inProgress" title="On Deck" (sectionClick)="handleSectionClick($event)">
    <ng-template #carouselItem let-item let-position="idx">
        <app-series-card [data]="item" [libraryId]="item.libraryId" (reload)="reloadInProgress($event)" (dataChanged)="reloadInProgress($event)"></app-series-card>
    </ng-template>
</app-carousel-reel>

<!-- TODO: Refactor this so we can use series actions here -->
<app-carousel-reel [items]="recentlyUpdatedSeries" title="Recently Updated Series">
    <ng-template #carouselItem let-item let-position="idx">
        <app-card-item [entity]="item" [title]="item.seriesName" [imageUrl]="imageService.getSeriesCoverImage(item.seriesId)" 
        [supressArchiveWarning]="true" (clicked)="handleRecentlyAddedChapterClick(item)" [count]="item.count"></app-card-item>
    </ng-template>
</app-carousel-reel>

<app-carousel-reel [items]="recentlyAddedSeries" title="Recently Added Series" (sectionClick)="handleSectionClick($event)">
    <ng-template #carouselItem let-item let-position="idx">
        <app-series-card [data]="item" [libraryId]="item.libraryId" (dataChanged)="loadRecentlyAddedSeries()"></app-series-card>
    </ng-template>
</app-carousel-reel>

<app-carousel-reel [items]="recentlyAddedChapters" title="Recently Added">
    <ng-template #carouselItem let-item let-position="idx">
        <app-card-item [entity]="item" [title]="item.title" [subtitle]="item.seriesName" [imageUrl]="imageService.getRecentlyAddedItem(item)" 
        [supressArchiveWarning]="true" (clicked)="handleRecentlyAddedChapterClick(item)"></app-card-item>
    </ng-template>
</app-carousel-reel>

<app-carousel-reel [items]="libraries" title="Libraries" (sectionClick)="handleSectionClick($event)">
    <ng-template #carouselItem let-item let-position="idx">
        <app-library-card [data]="item"></app-library-card>
    </ng-template>
</app-carousel-reel>
