<app-splash-container>
    <ng-container title><h2>Login</h2></ng-container>
    <ng-container body>
        <ng-container *ngIf="isLoaded"> 
            <form [formGroup]="loginForm" (ngSubmit)="login()" novalidate class="needs-validation" *ngIf="!firstTimeFlow">
                <div class="card-text">
                    <div class="form-group" style="width:100%">
                        <label for="username">Username</label>
                        <input class="form-control" formControlName="username" id="username" type="text">
                    </div>
                    
                    <div class="form-group" style="width:100%">
                        <label for="password">Password</label>
                        <input class="form-control" formControlName="password" id="password" type="password" autofocus>
                    </div>

                    <div>
                        <a href="/registration/reset-password">Forgot Password?</a>
                    </div>
                    
                    <div class="float-right">
                        <button class="btn btn-secondary alt" type="submit">Login</button>
                    </div>
                </div>
            </form>
        </ng-container>
    </ng-container>
</app-splash-container>