<div class="container-fluid" *ngIf="series !== undefined" style="padding-top: 10px">
    <div class="row mb-3">
        <div class="col-md-2 col-xs-4 col-sm-6">            
            <app-image class="poster" maxWidth="300px" [imageUrl]="seriesImage"></app-image>
        </div>
        <div class="col-md-10 col-xs-8 col-sm-6">
            <div class="row no-gutters">
                
                <h2>
                    {{series?.name}}
                </h2>
            </div>
            <div class="row no-gutters">
                <div>
                    <button class="btn btn-primary" (click)="read()" [disabled]="isLoading">
                        <span>
                            <i class="fa {{showBook ? 'fa-book-open' : 'fa-book'}}"></i>
                        </span>
                        <span class="read-btn--text">&nbsp;{{(hasReadingProgress) ? 'Continue' : 'Read'}}</span>
                    </button>
                </div>
                <div class="ml-2" *ngIf="isAdmin">
                    <button class="btn btn-secondary" (click)="openEditSeriesModal()" title="Edit Series information">
                        <span>
                            <i class="fa fa-pen" aria-hidden="true"></i>
                        </span>
                    </button>
                </div>
                <div class="ml-2" *ngIf="isAdmin || hasDownloadingRole">
                    <button class="btn btn-secondary" (click)="downloadSeries()" title="Download Series" [disabled]="downloadInProgress">
                        <ng-container *ngIf="downloadInProgress; else notDownloading">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span class="sr-only">Downloading...</span>
                        </ng-container>
                        <ng-template #notDownloading>
                            <i class="fa fa-arrow-alt-circle-down" aria-hidden="true"></i>
                        </ng-template>
                    </button>
                </div>
                <div class="ml-2">
                    <div class="card-actions">
                        <app-card-actionables [disabled]="actionInProgress" (actionHandler)="performAction($event)" [actions]="seriesActions" [labelBy]="series.name" iconClass="fa-ellipsis-h" btnClass="btn-secondary"></app-card-actionables>
                    </div>
                </div>
                <div class="ml-2">
                    <ngb-rating class="rating-star" [(rate)]="series!.userRating" (rateChange)="updateRating($event)" (click)="promptToReview()"></ngb-rating>
                    <button *ngIf="series?.userRating || series.userRating" class="btn btn-sm btn-icon" (click)="openReviewModal(true)" placement="top" ngbTooltip="Edit Review" attr.aria-label="Edit Review"><i class="fa fa-pen" aria-hidden="true"></i></button>
                </div>
            </div>
            <div class="row no-gutters">
                <!-- TODO: This will be the first of reviews section. Reviews will show your plus other peoples reviews in media cards like Plex does and this will be below metadata -->
                <app-read-more class="user-review {{userReview ? 'mt-1' : ''}}" [text]="series?.userReview || ''" [maxLength]="250"></app-read-more>
            </div>
            <div *ngIf="seriesMetadata" class="mt-2">
                <app-series-metadata-detail [seriesMetadata]="seriesMetadata" [series]="series"></app-series-metadata-detail>
            </div>


        </div>
    </div>

    <div>
        <app-bulk-operations [actionCallback]="bulkActionCallback"></app-bulk-operations>
        <ul ngbNav #nav="ngbNav" [(activeId)]="activeTabId" class="nav-tabs nav-pills" [destroyOnHide]="false" (navChange)="onNavChange($event)">
            <li [ngbNavItem]="TabID.Specials" *ngIf="hasSpecials">
              <a ngbNavLink>Specials</a>
              <ng-template ngbNavContent>
                <div class="row no-gutters">
                    <div *ngFor="let chapter of specials; let idx = index; trackBy: trackByChapterIdentity">
                        <app-card-item class="col-auto" *ngIf="chapter.isSpecial" [entity]="chapter" [title]="chapter.title || chapter.range" (click)="openChapter(chapter)"
                        [imageUrl]="imageService.getChapterCoverImage(chapter.id)"
                        [read]="chapter.pagesRead" [total]="chapter.pages" [actions]="chapterActions" (selection)="bulkSelectionService.handleCardSelection('special', idx, chapters.length, $event)" [selected]="bulkSelectionService.isCardSelected('special', idx)" [allowSelection]="true"></app-card-item>
                    </div>
                </div>
              </ng-template>
            </li>
            <li [ngbNavItem]="TabID.Storyline" *ngIf="libraryType !== LibraryType.Book && (hasNonSpecialVolumeChapters || hasNonSpecialNonVolumeChapters)">
                <a ngbNavLink>Storyline</a>
                <ng-template ngbNavContent>
                    <div class="row no-gutters">
                        <div *ngFor="let volume of volumes; let idx = index; trackBy: trackByVolumeIdentity">
                            <app-card-item class="col-auto" *ngIf="volume.number != 0" [entity]="volume" [title]="formatVolumeTitle(volume)" (click)="openVolume(volume)"
                            [imageUrl]="imageService.getVolumeCoverImage(volume.id) + '&offset=' + coverImageOffset"
                            [read]="volume.pagesRead" [total]="volume.pages" [actions]="volumeActions" (selection)="bulkSelectionService.handleCardSelection('volume', idx, volumes.length, $event)" [selected]="bulkSelectionService.isCardSelected('volume', idx)" [allowSelection]="true"></app-card-item>
                        </div>
                          <div *ngFor="let chapter of storyChapters; let idx = index; trackBy: trackByChapterIdentity">
                              <app-card-item class="col-auto" *ngIf="!chapter.isSpecial" [entity]="chapter" [title]="formatChapterTitle(chapter)" (click)="openChapter(chapter)"
                              [imageUrl]="imageService.getChapterCoverImage(chapter.id) + '&offset=' + coverImageOffset"
                              [read]="chapter.pagesRead" [total]="chapter.pages" [actions]="chapterActions" (selection)="bulkSelectionService.handleCardSelection('chapter', idx, storyChapters.length, $event)" [selected]="bulkSelectionService.isCardSelected('chapter', idx)" [allowSelection]="true"></app-card-item>
                          </div>
                    </div>
                </ng-template>
            </li>
            <li [ngbNavItem]="TabID.Volumes" *ngIf="hasNonSpecialVolumeChapters">
                <a ngbNavLink>Volumes</a>
                <ng-template ngbNavContent>
                    <div class="row no-gutters">
                          <div *ngFor="let volume of volumes; let idx = index; trackBy: trackByVolumeIdentity">
                                  <app-card-item class="col-auto" *ngIf="volume.number != 0" [entity]="volume" [title]="formatVolumeTitle(volume)" (click)="openVolume(volume)"
                                  [imageUrl]="imageService.getVolumeCoverImage(volume.id) + '&offset=' + coverImageOffset"
                                  [read]="volume.pagesRead" [total]="volume.pages" [actions]="volumeActions" (selection)="bulkSelectionService.handleCardSelection('volume', idx, volumes.length, $event)" [selected]="bulkSelectionService.isCardSelected('volume', idx)" [allowSelection]="true"></app-card-item>
                          </div>
                    </div>
                </ng-template>
              </li>
            <li [ngbNavItem]="TabID.Chapters" *ngIf="hasNonSpecialNonVolumeChapters">
              <a ngbNavLink>{{utilityService.formatChapterName(libraryType) + 's'}}</a>
              <ng-template ngbNavContent>
                  <div class="row no-gutters">
                        <div *ngFor="let chapter of chapters; let idx = index; trackBy: trackByChapterIdentity">
                            <app-card-item class="col-auto" *ngIf="!chapter.isSpecial" [entity]="chapter" [title]="formatChapterTitle(chapter)" (click)="openChapter(chapter)"
                            [imageUrl]="imageService.getChapterCoverImage(chapter.id) + '&offset=' + coverImageOffset"
                            [read]="chapter.pagesRead" [total]="chapter.pages" [actions]="chapterActions" (selection)="bulkSelectionService.handleCardSelection('chapter', idx, chapters.length, $event)" [selected]="bulkSelectionService.isCardSelected('chapter', idx)" [allowSelection]="true"></app-card-item>
                        </div>
                  </div>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="nav"></div>
    </div>

    <div class="mx-auto" *ngIf="isLoading" style="width: 200px;">
        <div class="spinner-border text-secondary loading" role="status">
            <span class="invisible">Loading...</span>
        </div>
    </div>
</div>