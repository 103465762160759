<div *ngIf="data !== undefined">
    <div class="modal-header">
        <h4 *ngIf="libraryType !== LibraryType.Comic else comicHeader" class="modal-title" id="modal-basic-title">
            {{parentName}} - {{data.number != 0 ? (isChapter ? 'Chapter ' : 'Volume ') + data.number : 'Special'}} Details</h4>
        <ng-template #comicHeader><h4 class="modal-title" id="modal-basic-title">
            {{parentName}} - {{data.number != 0 ? (isChapter ? 'Issue #' : 'Volume ') + data.number : 'Special'}} Details</h4>
        </ng-template>
        <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body scrollable-modal" *ngIf="utilityService.isChapter(data)">
        <ng-container *ngIf="utilityService.isChapter(data)">
            <app-chapter-metadata-detail [chapter]="data"></app-chapter-metadata-detail>
        </ng-container>
    </div>
    <div class="modal-body scrollable-modal" *ngIf="utilityService.isVolume(data)">
        <h4 *ngIf="utilityService.isVolume(data)">Information</h4>

        <ng-container *ngIf="utilityService.isVolume(data) || utilityService.isChapter(data)">
            <div class="row no-gutters">
                <div class="col">
                    Id: {{data.id}}
                </div>
                <div class="col" *ngIf="series !== undefined">
                    Format: <span class="badge badge-secondary">{{utilityService.mangaFormat(series.format) | sentenceCase}}</span>
                </div>
            </div>
            <div class="row no-gutters">
                <div class="col" *ngIf="data.hasOwnProperty('created')">
                    Added: {{(data.created | date: 'short') || '-'}}
                </div>
                <div class="col">
                    Pages: {{data.pages}}
                </div>
            </div>
        </ng-container>

        <h4 *ngIf="!utilityService.isChapter(data)">{{utilityService.formatChapterName(libraryType) + 's'}}</h4>
        <ul class="list-unstyled">
            <li class="media my-4" *ngFor="let chapter of chapters">
                <a (click)="readChapter(chapter)" href="javascript:void(0);" title="Read {{libraryType !== LibraryType.Comic ? 'Chapter ' : 'Issue #'}} {{chapter.number}}">
                    <app-image class="mr-2" width="74px" [imageUrl]="chapter.coverImage"></app-image>
                </a>
                <div class="media-body">
                    <h5 class="mt-0 mb-1">
                        <span *ngIf="chapter.number !== '0'; else specialHeader">
                            <span >
                                <app-card-actionables (actionHandler)="performAction($event, chapter)" [actions]="chapterActions" [labelBy]="utilityService.formatChapterName(libraryType, true, true) + formatChapterNumber(chapter)"></app-card-actionables>&nbsp;
                                {{utilityService.formatChapterName(libraryType, true, false) }} {{formatChapterNumber(chapter)}}
                            </span>
                            <span class="badge badge-primary badge-pill">
                                <span *ngIf="chapter.pagesRead > 0 && chapter.pagesRead < chapter.pages">{{chapter.pagesRead}} / {{chapter.pages}}</span>
                                <span *ngIf="chapter.pagesRead === 0">UNREAD</span>
                                <span *ngIf="chapter.pagesRead === chapter.pages">READ</span>
                            </span>
                        </span>
                        <ng-template #specialHeader>File(s)</ng-template>
                    </h5>
                    <ul class="list-group">
                        <li *ngFor="let file of chapter.files" class="list-group-item">
                            <span>{{file.filePath}}</span>
                            <div class="row no-gutters">
                                <div class="col">
                                    Pages: {{file.pages}}
                                </div>
                                <div class="col" *ngIf="data.hasOwnProperty('created')">
                                    Added: {{(data.created | date: 'short') || '-'}}
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </li>
        </ul>
        
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-info" [disabled]="!isAdmin" (click)="updateCover()">Update Cover</button>
        <button type="submit" class="btn btn-primary" (click)="close()">Close</button>
    </div>
</div>


