

<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{config.header}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" style="overflow-x: auto" [innerHtml]="config.content | safeHtml">
</div>
<div class="modal-footer">
    <div *ngFor="let btn of config.buttons">
        <button type="button" class="btn btn-{{btn.type}}" (click)="clickButton(btn)">{{btn.text}}</button>
    </div>
    
</div>
